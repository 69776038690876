@font-face {
  font-family: "Zilla Slab";
  font-style: normal;
  font-weight: 700;
  src:
    local("Zilla Slab-Bold"),
    url("/fonts/Zilla_Slab/ZillaSlab-Bold.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Zilla Slab";
  font-style: normal;
  font-weight: 600;
  src:
    local("Zilla Slab-SemiBold"),
    url("/fonts/Zilla_Slab/ZillaSlab-SemiBold.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Zilla Slab";
  font-style: normal;
  font-weight: 500;
  src:
    local("Zilla Slab-Medium"),
    url("/fonts/Zilla_Slab/ZillaSlab-Medium.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Zilla Slab";
  font-style: normal;
  font-weight: 300;
  src:
    local("Zilla Slab-Light"),
    url("/fonts/Zilla_Slab/ZillaSlab-Light.woff2") format("woff2");
  font-display: swap;
}
