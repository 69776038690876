@use "fonts/inter-ui";
@use "fonts/metropolis";
@use "fonts/zilla-slab";
@use "~@mozilla-protocol/core/protocol/css/includes/lib" as *;
@use "~@mozilla-protocol/core/protocol/css/base/elements/reset";

/*
  next.js will rename the file if any of the content changes (after processing
  and compression), which will invalidate the cache.  Django makes further
  changes, such as fixing the sourcemap path. If an update to
  django.contrib.staticfiles or RelayStaticFilesStorage changes the content,
  increment the "cache version" comment below and in color.scss to force a
  content change and cache invalidation.
*/
/*! gv=1 */

a {
  color: inherit;
  text-decoration: none;
}

/*
  Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
  TODO MPP-3959: Try changes from June 2023 and later
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html,
body,
#__next,
#overlayProvider {
  font-family: $font-stack-base;
  height: 100%;
}

#overlayProvider {
  display: flex;
  flex-direction: column;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: $layout-xl;

  @media (prefers-reduced-motion) {
    & {
      scroll-behavior: auto;
    }
  }
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

#root,
#__next {
  isolation: isolate;
}

input[type="search"]::-webkit-search-decoration {
  display: none;
}

// Styles in the `add-on-overrides` layer (which the add-on injects)
// should override those in the `add-on-overridable` layer.
@layer add-on-overridable, add-on-overrides;
@layer add-on-overridable {
  .is-visible-with-addon {
    // This class hides an element anything in the regular website;
    // however, the add-on injects a stylesheet that reveals elements with this class.
    display: none;
    visibility: collapse;
  }

  .is-hidden-with-addon {
    // This class doesn't do anything in the regular website;
    // however, the add-on injects a stylesheet that hides elements with this class.
  }
}
